import React from "react";

import Layout from "../components/v2/Layout";
import PageSection from "../components/v2/PageSection.jsx";
import Hero from "../components/v2/Hero";
import ContentBlock from "../components/v2/ContentBlock";
import Image from "../components/v2/Image";
import ImageGrid from "../components/v2/ImageGrid";
import PrevNextNavFooter from "../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter";
import { pageLinks } from "../data/pageLinks.js";

import hero_img from "../images/2. Photography/photography-header-2560x1728.jpg";

import people_full_screen_img from "../images/2. Photography/photography-people-2560x1280.jpg";

import product_full_screen_img from "../images/2. Photography/photography-product-2560x1280.jpg";

import place_full_screen_img from "../images/2. Photography/photography-place-2560x1280.jpg";

import lifestyle_full_screen_img from "../images/2. Photography/photography-lifestyle-2560x1280.jpg";

const introText =
  "Our photography reflects who we are as a brand; our personality, our beliefs and our products. Photography should aim to be fresh, optimistic, relatable. It's another way to connect with our New Zealanders and like everything we do, is a representation of <em>Let’s Go Places</em>.";

const Photography = ({ location }) => {
  return (
    <Layout appLocation={location}>
      <Hero
        image={hero_img}
        text={introText}
        navindex="0"
        textPos={"flex-start"}
        marginTop={"10%"}
      />
      <PageSection sectionId="overview">
        <ContentBlock>
          <div className="text">
            <p>
              Here are some broad guidelines for capturing places, people and
              products. Undoubtedly from time to time campaigns will require
              specific photography considerations, in these cases the spirit of
              the following principles and our tone should be applied.
            </p>
          </div>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="people">
        <ContentBlock noTopSpacing={true}>
          <h2>People</h2>
          <div className="text">
            <p>
              We will often photograph people as part of campaigns. We always
              look for people who feel authentic and natural. Whether they’re in
              a lifestyle or story moment, or are posed and looking directly at
              the camera, we always aim for genuine and natural performance and
              expression.
            </p>
            <p>
              We would look to use natural light or create the feeling of
              natural light sources. People cast for campaigns should provide
              personality, character and humanity. They help us deliver our own
              personality, tone and manner through visual storytelling. We
              should always look to reflect the diversity of modern New Zealand
              through our campaigns.
            </p>
            <p>
              Whenever people are featured in campaigns it should be ensured
              that consent has been gained for usage before assets begin to be
              used.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid fullWidth={true}>
          <Image width="full" src={people_full_screen_img}></Image>
        </ImageGrid>
      </PageSection>
      <PageSection sectionId="place">
        <ContentBlock>
          <h2>Place</h2>
          <div className="text">
            <p>
              Chosen locations should look to capture New Zealandness. Whether
              that place is the raw beauty of our big landscapes, the green of
              our native bush, the charm of a small town or the colour of a
              muralled brick wall. Making all our marketing communications feel
              of New Zealand is what photography can help us do.
            </p>
            <p>
              We always aim for natural scenes and avoid making cars in
              environments look too retouched or cut and pasted in to scenes. We
              avoid having vehicles in places that suggest any disrespect for
              the environment and protected or sacred sites. We never disregard
              safety or driving codes.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid fullWidth={true}>
          <Image width="full" src={place_full_screen_img}></Image>
        </ImageGrid>
      </PageSection>
      <PageSection sectionId="product">
        <ContentBlock>
          <h2>Product</h2>
          <div className="text">
            <p>
              Our vehicles (and other products) are shot in environments or
              against white backgrounds for clear-cutting. Highlighting the
              driver’s side is often preferred, otherwise shoot the angle that
              best conveys the unique attributes of the vehicle. Avoid lens
              distortion, hotspots and ensure an accurate representation of the
              vehicle colour is captured.
            </p>
            <p>
              When featuring vehicles in campaign assets, consideration should
              be given as to the number plate used on the vehicles.
            </p>
            <p>
              If vehicles are shown in a natural environment then genuine number
              plates are preferred. If vehicles are on display or feature in a
              non-real world environment, then name plates should be used.
            </p>
            <p>Privacy should also be considered when shooting vehicles.</p>
            <p>
              If the vehicle shown is registered to a company then no further
              action is needed, but if the vehicle is privately owned then
              consent needs to be agreed. If consent isn’t given then an
              alternative plate should be used instead.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid fullWidth={true}>
          <Image width="full" src={product_full_screen_img}></Image>
        </ImageGrid>
      </PageSection>
      <PageSection sectionId="lifestyle">
        <ContentBlock>
          <h2>Lifestyle</h2>
          <div className="text">
            <p>
              For channels such as the website or social, we will also need to
              capture our vehicles in their natural habitat, giving a sense of
              the lifestyle they both fit into and make possible.
            </p>
            <p>
              This is where we showcase the places and adventures a Toyota
              vehicle can take you, enabling viewers to experience a highly
              meaningful <em>Let’s Go Places</em> lifestyle.
            </p>
            <p>
              It’s important to help the car look it’s absolute best too, making
              sure the model stands out in its environment. For example, placing
              a bright-coloured car in a neutral background, or finding a
              bright-coloured graffiti wall to place behind a more
              neutral-coloured car.
            </p>
            <p>
              If we include people, we should get a sense of a story being
              played out. For example, people heading off to do the activity in
              the place the vehicle has brought them, or loading the car with
              interesting things that hint at where they’re going.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid fullWidth={true}>
          <Image width="full" src={lifestyle_full_screen_img}></Image>
        </ImageGrid>
      </PageSection>
      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Tagline"
        previousUrl={pageLinks.tagline}
        nextText="Next"
        nextHeader="Hey Toyota"
        nextUrl={pageLinks["hey-toyota"]}
      />
    </Layout>
  );
};
export default Photography;