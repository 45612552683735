import React from 'react';
import placeholderImg from '../../images/placeholder_500x500.png';
import placeholderImgFull from '../../images/placeholder_800x500.png';

const Image = (props) => {
  let width = null;
  let classes = 'image';
  let overlayClasses = 'overlay-text';

  if (props.overlayStyle) {
    overlayClasses += ` ${props.overlayStyle}`;
  }

  switch (props.width) {
    case 'full':
      classes += ' full';
      break;
    case 'half':
      classes += ' half';
      break;
    case 'quarter':
      classes += ' quarter';
      break;
    default:
      width = props.width;
  }
  return (
    <div
      className={classes}
      style={{
        width,
        padding: props?.padding
      }}>
      {!props.placeholder ? (
        <img alt="" src={props.src} style={props.style} />
      ) : (
        <img
          alt=""
          src={props.width === 'full' ? placeholderImgFull : placeholderImg}
        />
      )}

      {props.children && (
        <div className={overlayClasses}>
          <div className="text-inner">{props.children}</div>
        </div>
      )}

      {props.caption && (
        <div className="caption" style={{ textAlign: 'center' }}>
          <span dangerouslySetInnerHTML={{ __html: props.caption }}></span>
        </div>
      )}
    </div>
  );
};

export default Image;
