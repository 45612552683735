import React from "react";

const ImageGrid = (props) => {
  let classes = "image-grid";
  if (props.vertPadding) {
    classes += " vert-padding";
  }
  return (
    <div className={classes}>
      {!props.fullWidth && <div className="inner-center">{props.children}</div>}
      {props.fullWidth && <div className="grid-inner">{props.children}</div>}
    </div>
  );
};

export default ImageGrid;
