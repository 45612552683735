import React from 'react';

const Hero = ({ image, bgColor, navindex, text, textPos, marginTop }) => {
  const textColor = image || bgColor === '#eb0a1e' ? 'white' : '#909090';
  return (
    <div className="hero">
      <div
        className="page-header image-header"
        id="overview"
        data-navindex={navindex || '0'}
        style={{
          backgroundColor: bgColor || '#f1f2f3'
        }}>
        <div
          className="content-flex"
          style={{
            color: '#000',
            textAlign: 'left',
            alignItems: `${textPos}`
          }}>
          <h1
            className="copy"
            style={{
              color: textColor,
              top: '-10%',
              textAlign: 'left',
              marginTop: `${marginTop}`
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>

        <div
          className="background"
          style={{ backgroundColor: bgColor || '#f1f2f3' }}>
          {image && (
            <div style={{ height: '100%' }}>
              <img src={image} alt="brand_header_desktop pic" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
